import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Button,
  Form,
  InputGroup,
  Accordion,
  Modal,
} from "react-bootstrap";
import { useParams } from "react-router-dom";
import { css } from "@emotion/react";
import { db } from "./firebase";
// import { Link, Navigate } from "react-router-dom";
import { useUserAuth } from "./UserAuthContext";
import Slider, { SliderThumb } from "@mui/material/Slider";
import { styled } from "@mui/material/styles";

import Grid from "@mui/material/Grid";
import MuiInput from "@mui/material/Input";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import {
  collection,
  query,
  where,
  onSnapshot,
  doc,
  updateDoc,
} from "firebase/firestore";
const override = css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  margin-top: -10%;
`;
function EditTracking() {
  let { user } = useUserAuth();
  const [masterAdmin, setMasterAdmin] = useState(false);
  //modal
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  //tracking from url
  const { id } = useParams();

  //loading animatiom
  const [loading, setLoading] = useState(false);

  const docRef = collection(db, "details");

  const [docId, setDocid] = useState("");

  // const [trackingDetails, setTrackingDetails] = useState([]);

  const [currentLocation, setCurrentLocation] = useState("");
  const [packages, setPackages] = useState("");
  const [destination, setDestination] = useState("");
  // const [pickUpDate, setPickUpDate] = useState("");
  const [expectedDeliveryDate, setExpectedDeliveryDate] = useState("");
  const [typeOfShipment, setTypeOfShipment] = useState("");
  const [previousLocation, setPreviousLocation] = useState("");

  const [service, setService] = useState("");
  const [recieversName, setRecieversName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [address, setAddress] = useState("");
  const [trackingNumber, setTrackingNumber] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [bluePercentage, setBluePercentage] = useState("");
  const [redPercentage, setRedPercentage] = useState("");
  const [deliveryMessage, setDeliveryMessage] = useState("");
  const [infoMessage, setInfoMessage] = useState("");
  const [admin, setAdmin] = useState("");

  useEffect(() => {
    setLoading(true);
    if (user.email === "ceaser@gmail.com") {
      setMasterAdmin(true);
    }
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, []);

  const editHandler = () => {
    const docSnap = query(docRef, where("trackingNumber", "==", id));
    const unsubscribe = onSnapshot(docSnap, (querySnapshot) => {
      querySnapshot.forEach((doc) => {
        setDocid(doc.id);
        setCurrentLocation(doc.data().currentLocation);
        setPackages(doc.data().packages);
        // setPickUpDate(doc.data().pickUpDate);
        setExpectedDeliveryDate(doc.data().expectedDeliveryDate);
        setTypeOfShipment(doc.data().typeOfShipment);
        setPreviousLocation(doc.data().previousLocation);
        setService(doc.data().service);
        setRecieversName(doc.data().recieversName);
        setService(doc.data().service);
        setPhoneNumber(doc.data().phoneNumber);
        setAddress(doc.data().address);
        setTrackingNumber(doc.data().trackingNumber);
        setErrorMessage(doc.data().errorMessage);
        setDeliveryMessage(doc.data().deliveryMessage);
        setDestination(doc.data().destination);
        setRedPercentage(doc.data().redPercentage);
        setBluePercentage(doc.data().bluePercentage);
        setAdmin(doc.data().admin);
        setInfoMessage(doc.data().infoMessage);
      });
    });
  };

  useEffect(() => {
    editHandler();
  }, []);

  const submitHandler = (event) => {
    event.currentTarget.disabled = true;
    const docRef2 = doc(db, "details", docId);
    updateDoc(docRef2, {
      bluePercentage: bluePercentage.toString(),
      redPercentage: redPercentage.toString(),
      errorMessage: errorMessage,
      expectedDeliveryDate: expectedDeliveryDate,
      // pickUpDate: pickUpDate,
      currentLocation: currentLocation,
      packages: packages,
      typeOfShipment: typeOfShipment,
      previousLocation: previousLocation,
      recieversName: recieversName,
      phoneNumber: phoneNumber,
      address: address,
      destination: destination,
      trackingNumber: trackingNumber,
      deliveryMessage: deliveryMessage,
      infoMessage: infoMessage,
    }).then((snapshot) => {
      handleShow();
    });
  };

  //slider

  const Input = styled(MuiInput)`
    width: 42px;
  `;
  //blue slider
  const [value, setValue] = useState(0);

  const handleSliderChange = (event, newValue) => {
    setBluePercentage(newValue);
  };

  const handleInputChange = (event) => {
    setBluePercentage(
      event.target.value === "" ? "" : Number(event.target.value)
    );
  };

  const handleBlur = () => {
    if (value < 0) {
      setValue(0);
    } else if (value > 100) {
      setValue(100);
    }
  };

  //red slider
  const [valueRed, setValueRed] = useState(0);

  const handleSliderChangeRed = (event, newValueRed) => {
    setRedPercentage(newValueRed);
  };

  const handleInputChangeRed = (event) => {
    setRedPercentage(
      event.target.value === "" ? "" : Number(event.target.value)
    );
  };

  const handleBlurRed = () => {
    if (valueRed < 0) {
      setValueRed(0);
    } else if (valueRed > 100) {
      setValueRed(100);
    }
  };

  //select button
  const handleChangeTypeOfShipment = (event) => {
    setTypeOfShipment(event.target.value);
  };

  return (
    <div className="fxg-main-content" style={{ marginTop: "50px" }}>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Update Successful</Modal.Title>
        </Modal.Header>
        <Modal.Body>Traking Updated</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          {/* <Link to={`/tracking/${trackingNumber}`}> */}
          <Button
            variant="primary"
            onClick={() =>
              (window.location.href = `/tracking/${trackingNumber}`)
            }
          >
            View Changes
          </Button>
          {/* </Link> */}
        </Modal.Footer>
      </Modal>

      {/* {loading ? (
        <ClimbingBoxLoader
          size={15}
          loading={loading}
          color={"#201e1e"}
          css={override}
        />
      ) : ( */}
      <main className="content">
        <div className="container-fluid p-0">
          <h1 className="h3 mb-3">Edit Tracking</h1>
        </div>
        <div className="row">
          <div className="col-xl-6 col-xxl-5 d-flex">
            <div className="w-100">
              {" "}
              <div className="card flex-fill w-100">
                {/* <div className="card-header">
                    <h5 className="card-title mb-0">Recent Movement</h5>
                  </div> */}
                <div className="card-body ">
                  <Form>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="2">
                        Progress color
                      </Form.Label>
                      <Col sm="10">
                        <InputGroup className="mb-3">
                          <Grid container spacing={2} alignItems="center">
                            <Grid item xs>
                              <Slider
                                value={parseInt(bluePercentage)}
                                onChange={handleSliderChange}
                                aria-labelledby="input-slider"
                                style={{ color: "#3b7ddd", height: "8px" }}
                              />
                            </Grid>
                            <Grid item>
                              <Input
                                value={parseInt(bluePercentage)}
                                size="small"
                                onChange={handleInputChange}
                                onBlur={handleBlur}
                                inputProps={{
                                  step: 0,
                                  min: 0,
                                  max: 100,
                                  type: "number",
                                  "aria-labelledby": "input-slider",
                                }}
                              />
                            </Grid>
                          </Grid>
                        </InputGroup>
                        <InputGroup className="mb-3">
                          <Grid container spacing={2} alignItems="center">
                            <Grid item xs>
                              <Slider
                                value={parseInt(redPercentage)}
                                onChange={handleSliderChangeRed}
                                aria-labelledby="input-slider"
                                style={{ color: "#d5343a", height: "8px" }}
                              />
                            </Grid>
                            <Grid item>
                              <Input
                                value={redPercentage}
                                size="small"
                                onChange={handleInputChangeRed}
                                onBlur={handleBlurRed}
                                inputProps={{
                                  step: 0,
                                  min: 0,
                                  max: 100,
                                  type: "number",
                                  "aria-labelledby": "input-slider",
                                }}
                              />
                            </Grid>
                          </Grid>
                        </InputGroup>
                      </Col>
                    </Form.Group>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="2">
                        Error For Billing
                      </Form.Label>
                      <Col sm="10">
                        <Form.Control
                          as="textarea"
                          rows={3}
                          placeholder=""
                          value={errorMessage}
                          onChange={(e) => setErrorMessage(e.target.value)}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="2">
                        Package Update
                      </Form.Label>
                      <Col sm="10">
                        <Form.Control
                          as="textarea"
                          rows={3}
                          placeholder=""
                          value={infoMessage}
                          onChange={(e) => setInfoMessage(e.target.value)}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="2">
                        Delivery Date
                      </Form.Label>
                      <Col sm="10">
                        <Form.Control
                          type="text"
                          placeholder=""
                          value={expectedDeliveryDate}
                          onChange={(e) =>
                            setExpectedDeliveryDate(e.target.value)
                          }
                        />
                      </Col>
                    </Form.Group>
                    {/* <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="2">
                        Pickup Date
                      </Form.Label>
                      <Col sm="10">
                        <Form.Control
                          type="text"
                          placeholder=""
                          value={pickUpDate}
                          onChange={(e) => setPickUpDate(e.target.value)}
                        />
                      </Col>
                    </Form.Group> */}
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="2">
                        Current Location{" "}
                      </Form.Label>
                      <Col sm="10">
                        <Form.Control
                          type="text"
                          placeholder="Package Current Location"
                          value={currentLocation}
                          onChange={(e) => setCurrentLocation(e.target.value)}
                        />
                      </Col>
                    </Form.Group>
                    <br />

                    <Accordion>
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>Others</Accordion.Header>
                        <Accordion.Body>
                          {/* <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="formPlaintextPassword"
                      >
                        <Form.Label column sm="2">
                          Reciever's Name
                        </Form.Label>
                        <Col sm="10">
                          <Form.Control
                            type="text"
                            // placeholder={trackingDetails.address}
                            value={recieversName}
                            onChange={(e) => setRecieversName(e.target.value)}
                          />
                        </Col>
                      </Form.Group> */}
                          {/* <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="formPlaintextPassword"
                      >
                        <Form.Label column sm="2">
                          Contact
                        </Form.Label>
                        <Col sm="10">
                          <Form.Control
                            type="text"
                            placeholder="Reciever's PhoneNumber"
                            value={phoneNumber}
                            onChange={(e) => setPhoneNumber(e.target.value)}
                          />
                        </Col>
                      </Form.Group> */}
                          {/* <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="formPlaintextPassword"
                      >
                        <Form.Label column sm="2">
                          Address
                        </Form.Label>
                        <Col sm="10">
                          <Form.Control
                            type="text"
                            placeholder="Reciever's Address"
                            value={address}
                            onChange={(e) => setAddress(e.target.value)}
                          />
                        </Col>
                      </Form.Group> */}
                          <Form.Group
                            as={Row}
                            className="mb-3"
                            controlId="formPlaintextPassword"
                          >
                            <Form.Label column sm="2">
                              Packages
                            </Form.Label>
                            <Col sm="10">
                              <Form.Control
                                type="text"
                                placeholder="Number Of Package"
                                value={packages}
                                onChange={(e) => setPackages(e.target.value)}
                              />
                            </Col>
                          </Form.Group>
                          {/* <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="formPlaintextPassword"
                      >
                        <Form.Label column sm="2">
                          Destination
                        </Form.Label>
                        <Col sm="10">
                          <Form.Control
                            type="text"
                            placeholder="Package Destination"
                            value={destination}
                            onChange={(e) => setDestination(e.target.value)}
                          />
                        </Col>
                      </Form.Group> */}
                          <Form.Group
                            as={Row}
                            className="mb-3"
                            controlId="formPlaintextPassword"
                          >
                            <Form.Label column sm="2">
                              Mode Of Shipment
                            </Form.Label>

                            <FormControl
                              sx={{ m: 1, minWidth: 100 }}
                              size="small"
                            >
                              <Select
                                value={typeOfShipment}
                                onChange={handleChangeTypeOfShipment}
                                displayEmpty
                                inputProps={{ "aria-label": "Without label" }}
                              >
                                <MenuItem value="Truck">Truck</MenuItem>
                                <MenuItem value={"Air Cargo"}>
                                  Air Cargo
                                </MenuItem>
                                <MenuItem value={"Cargo Ship"}>
                                  Cargo Ship
                                </MenuItem>
                              </Select>
                            </FormControl>

                            {/*                             
                            <Col sm="10">
                              <Form.Control
                                type="text"
                                placeholder="eg truck"
                                value={typeOfShipment}
                                onChange={(e) =>
                                  setTypeOfShipment(e.target.value)
                                }
                              />
                            </Col> */}
                          </Form.Group>

                          <Form.Group
                            as={Row}
                            className="mb-3"
                            controlId="formPlaintextPassword"
                          >
                            <Form.Label column sm="2">
                              Previous Location
                            </Form.Label>
                            <Col sm="10">
                              <Form.Control
                                type="text"
                                placeholder=""
                                value={previousLocation}
                                onChange={(e) =>
                                  setPreviousLocation(e.target.value)
                                }
                              />
                            </Col>
                          </Form.Group>
                          {/* <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="formPlaintextPassword"
                      >
                        <Form.Label column sm="2">
                          Services
                        </Form.Label>
                        <Col sm="10">
                          <Form.Control
                            type="text"
                            placeholder="eg standard "
                            value={service}
                            onChange={(e) => setService(e.target.value)}
                          />
                        </Col>
                      </Form.Group> */}
                          {/* <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="formPlaintextPassword"
                      >
                        <Form.Label column sm="2">
                          Tracking Number
                        </Form.Label>
                        <Col sm="10">
                          <Form.Control
                            type="text"
                            placeholder=""
                            value={trackingNumber}
                            onChange={(e) => setTrackingNumber(e.target.value)}
                          />
                        </Col>
                      </Form.Group> */}
                          {/* <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="2">
                            Progress
                          </Form.Label>
                          <Col sm="10">
                            <Form.Control
                              type="text"
                              placeholder="between 1-100"
                              value={progress}
                              onChange={(e) => setProgress(e.target.value)}
                            />
                          </Col>
                        </Form.Group> */}
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                    <br />
                    {masterAdmin ? (
                      <Accordion>
                        <Accordion.Item eventKey="0">
                          <Accordion.Header>Admin</Accordion.Header>
                          <Accordion.Body>
                            <Form.Group
                              as={Row}
                              className="mb-3"
                              controlId="formPlaintextPassword"
                            >
                              <Form.Label column sm="2">
                                Reciever's Name
                              </Form.Label>
                              <Col sm="10">
                                <Form.Control
                                  type="text"
                                  placeholder="Package Current Location"
                                  value={recieversName}
                                  onChange={(e) =>
                                    setRecieversName(e.target.value)
                                  }
                                />
                              </Col>
                            </Form.Group>{" "}
                            <Form.Group
                              as={Row}
                              className="mb-3"
                              controlId="formPlaintextPassword"
                            >
                              <Form.Label column sm="2">
                                Contact
                              </Form.Label>
                              <Col sm="10">
                                <Form.Control
                                  type="text"
                                  placeholder="Reciever's PhoneNumber"
                                  value={phoneNumber}
                                  onChange={(e) =>
                                    setPhoneNumber(e.target.value)
                                  }
                                />
                              </Col>
                            </Form.Group>{" "}
                            <Form.Group
                              as={Row}
                              className="mb-3"
                              controlId="formPlaintextPassword"
                            >
                              <Form.Label column sm="2">
                                Address
                              </Form.Label>
                              <Col sm="10">
                                <Form.Control
                                  type="text"
                                  placeholder="Reciver's Address"
                                  value={address}
                                  onChange={(e) => setAddress(e.target.value)}
                                />
                              </Col>
                            </Form.Group>{" "}
                            <Form.Group
                              as={Row}
                              className="mb-3"
                              controlId="formPlaintextPassword"
                            >
                              <Form.Label column sm="2">
                                Destination
                              </Form.Label>
                              <Col sm="10">
                                <Form.Control
                                  type="text"
                                  placeholder="Package Destination"
                                  value={destination}
                                  onChange={(e) =>
                                    setDestination(e.target.value)
                                  }
                                />
                              </Col>
                            </Form.Group>{" "}
                            <Form.Group
                              as={Row}
                              className="mb-3"
                              controlId="formPlaintextPassword"
                            >
                              <Form.Label column sm="2">
                                Tracking Number
                              </Form.Label>
                              <Col sm="10">
                                <Form.Control
                                  type="text"
                                  placeholder=""
                                  value={trackingNumber}
                                  onChange={(e) =>
                                    setTrackingNumber(e.target.value)
                                  }
                                />
                              </Col>
                            </Form.Group>
                            <Form.Group
                              as={Row}
                              className="mb-3"
                              controlId="formPlaintextPassword"
                            >
                              <Form.Label column sm="2">
                                Delivery Message
                              </Form.Label>
                              <Col sm="10">
                                <Form.Control
                                  type="text"
                                  placeholder=""
                                  value={deliveryMessage}
                                  onChange={(e) =>
                                    setDeliveryMessage(e.target.value)
                                  }
                                />
                              </Col>
                            </Form.Group>
                            <Form.Group
                              as={Row}
                              className="mb-3"
                              controlId="formPlaintextPassword"
                            >
                              <Form.Label column sm="2">
                                Created By
                              </Form.Label>
                              <Col sm="10">
                                <Form.Control value={admin} disabled />
                              </Col>
                            </Form.Group>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    ) : (
                      ""
                    )}
                  </Form>
                  <br />
                  <Button
                    variant="primary"
                    type="submit"
                    size="lg"
                    onClick={submitHandler}
                  >
                    Edit
                  </Button>
                </div>
              </div>
            </div>
          </div>

          <div className="col-xl-6 col-xxl-7"></div>
        </div>
      </main>
      {/* )} */}
    </div>
  );
}

export default EditTracking;
