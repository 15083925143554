import React, { useEffect, useState } from "react";
import { useUserAuth } from "./UserAuthContext";
import { Button, Table, Modal } from "react-bootstrap";
import { css } from "@emotion/react";
import { db } from "./firebase";
import {
  collection,
  onSnapshot,
  query,
  where,
  orderBy,
  deleteDoc,
  doc,
  getDocs,
} from "firebase/firestore";

function Edit() {
  const [data, setData] = useState([]);
  let { user } = useUserAuth();
  const trackingRef = collection(db, "details");

  //modal control
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [modalId, setModalId] = useState([""]);
  const [modalName, setModalName] = useState([""]);
  const [modalTrackingNumber, setModalTrackingNumber] = useState([""]);

  useEffect(() => {
    const fetchData = async () => {
      let list = [];
      console.log(user.email);
      try {
        if (user.email === "ceaser@gmail.com") {
          const q = query(trackingRef, orderBy("timestamp", "desc"));

          const querySnapshot = await getDocs(q);
          querySnapshot.forEach((doc) => {
            list.push({ id: doc.id, info: doc.data() });
          });
          setData(list);
          console.log(list);
        } else {
          const q = query(
            trackingRef,
            where("admin", "==", user.email),
            orderBy("timestamp", "desc")
          );

          const unsubscribe = onSnapshot(q, (querySnapshot) => {
            querySnapshot.forEach((doc) => {
              list.push({ id: doc.id, info: doc.data() });
            });
            setData(list);
          });
        }
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, []);

  const handleDelete = async (id) => {
    const docReff = doc(db, "details", modalId);
    await deleteDoc(docReff);
    handleClose();
    setModalId("");
    setModalName("");
    setModalTrackingNumber("");
    alert("Tracking Deleted");

    window.location.reload();
  };
  return (
    <div className="fxg-main-content" style={{ marginTop: "50px" }}>
      <div className="container">
        <>
          <h1 className="fxg-title">Edit Tracking Details</h1>

          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Delete Tracking</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <center>
                {" "}
                <h4>Are you sure you want to delete {modalTrackingNumber}</h4>
              </center>
              <br /> Client Name : {modalName}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              <Button variant="danger" onClick={handleDelete}>
                Delete
              </Button>
            </Modal.Footer>
          </Modal>

          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Tracking Number</th>
                <th>Name</th>
                <th>Date</th>
                {user.email === "ceaser@gmail.com" && <th>Admin</th>}
                <th>Edit</th>
              </tr>
            </thead>
            <tbody>
              {data.map(({ id, info }) => (
                <tr key={id}>
                  <td>{info.trackingNumber}</td>
                  <td>{info.recieversName}</td>
                  <td>
                    {info.timestamp.toDate().toLocaleString("en-GB", {
                      timezone: "UTC",

                      hour12: true,
                    })}
                  </td>
                  {user.email === "ceaser@gmail.com" && <td>{info.admin}</td>}

                  <td>
                    <Button
                      onClick={() =>
                        (window.location.href = `/update/${info.trackingNumber}`)
                      }
                    >
                      Edit
                    </Button>

                    <Button
                      variant="success"
                      onClick={() =>
                        (window.location.href = `/tracking/${info.trackingNumber}`)
                      }
                    >
                      View
                    </Button>

                    {user.email === "ceaser@gmail.com" && (
                      <Button
                        variant="danger"
                        key={id}
                        onClick={() => {
                          setModalId(id);
                          setModalTrackingNumber(info.trackingNumber);
                          setModalName(info.recieversName);
                          handleShow();
                        }}
                      >
                        Delete
                      </Button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </>
      </div>
    </div>
  );
}
export default Edit;
