import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDqkHVm88_Vi3XkWPeG7szIZP3dOrnWJfo",

  authDomain: "fast247courier.firebaseapp.com",

  projectId: "fast247courier",

  storageBucket: "fast247courier.appspot.com",

  messagingSenderId: "721488929507",

  appId: "1:721488929507:web:18f59eb2cb0cdc3030dbd6",

  measurementId: "G-RDZL5XCPY9",
};

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
const db = getFirestore(app);
export { app, db };
