import React, { useEffect, useState } from "react";
import { useUserAuth } from "./UserAuthContext";
import { Button, ButtonGroup, ToggleButton } from "react-bootstrap";
import "./css.css";
import { db } from "./firebase";
import { collection, query, where, onSnapshot } from "firebase/firestore";
// import { Link } from "react-router-dom";
function DashBoard() {
  const { user, logOut } = useUserAuth();
  // console.log(user.email);
  const trackingRef = collection(db, "details");
  const [data, setData] = useState([]);
  const [radioValue, setRadioValue] = useState("1");

  const radios = [
    { name: "active", value: "true" },
    { name: "disabled", value: "false" },
    // { name: "Radio", value: "3" },
  ];

  const handleLogout = async () => {
    try {
      await logOut();
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      let list = [];
      console.log(user.email);
      try {
        if (user.email === "ceaser@gmail.com") {
          const q = query(trackingRef);
          const unsubscribe = onSnapshot(q, (querySnapshot) => {
            querySnapshot.forEach((doc) => {
              list.push({ id: doc.id, info: doc.data() });
            });
            setData(list);
            // console.log(list.length);
          });
        } else {
          const q = query(trackingRef, where("admin", "==", user.email));

          const unsubscribe = onSnapshot(q, (querySnapshot) => {
            querySnapshot.forEach((doc) => {
              list.push({ id: doc.id, info: doc.data() });
            });
            setData(list);
            // console.log(list.length);
          });
        }
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, []);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     let list = [];

  //     try {
  //       const q = query(
  //         trackingRef,

  //         where("admin", "==", user.email)
  //       );

  //       const unsubscribe = onSnapshot(q, (querySnapshot) => {
  //         querySnapshot.forEach((doc) => {
  //           list.push({ id: doc.id, info: doc.data() });
  //         });
  //         setData(list);
  //       });
  //     } catch (err) {
  //       console.log(err);
  //     }
  //   };
  //   fetchData();
  // }, []);

  return (
    // <Container>
    //   <div>
    //     <a className="nav-link" href="#" onClick={handleLogout}>
    //       Logout
    //     </a>
    //     <FontAwesomeIcon icon={faCoffee} size="6x" />
    //     <h1>h</h1>DashBoard
    //     <Row>
    //       <Col>1 of 3</Col>
    //       <Col>2 of 3</Col>
    //       <Col>3 of 3</Col>
    //     </Row>
    //   </div>
    // </Container>
    <body>
      <div class="wrapper">
        <div class="main">
          <nav class="navbar navbar-expand navbar-light navbar-bg">
            <a class="sidebar-toggle js-sidebar-toggle">
              <i class="hamburger align-self-center"></i>
            </a>

            <div class="navbar-collapse collapse">
              <ul class="navbar-nav navbar-align">
                <li class="nav-item dropdown">
                  {/* <a
                    class="nav-icon dropdown-toggle d-inline-block d-sm-none"
                    href="#"
                    data-bs-toggle="dropdown"
                  >
                    <i class="align-middle" data-feather="settings"></i>
                  </a> */}
                  <Button onClick={handleLogout} variant="danger">
                    Logout
                  </Button>
                  {/*    <a
                    class="nav-link dropdown-toggle d-none d-sm-inline-block"
                    href="#"
                    data-bs-toggle="dropdown"
                  >
                   <img
                      src="img/avatars/avatar.jpg"
                      class="avatar img-fluid rounded me-1"
                      alt="Charles Hall"
                    /> */}
                  {/* <span class="text-dark">Charles Hall</span> 
                  </a>*/}
                  {/* <a className="nav-link" href="#" onClick={handleLogout}></a> */}
                </li>
              </ul>
            </div>
          </nav>

          <main class="content">
            <div class="container-fluid p-0">
              <h1 class="h3 mb-3">Admin Panel</h1>

              <div class="row">
                <div class="col-12">
                  <div class="card">
                    <div class="card-header">
                      <h5 class="card-title mb-0">Welcome, {user.email}</h5>
                    </div>
                    {/* <div class="card-body"></div> */}
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-xl-6 col-xxl-5 d-flex">
                <div class="w-100">
                  <div class="row">
                    <div class="col-sm-6">
                      <div class="card">
                        <div class="card-body">
                          <div class="row">
                            <div class="col mt-0">
                              {/* <Link to="/create"> */}
                              <Button
                                variant="outline-primary"
                                onClick={() =>
                                  (window.location.href = "/create")
                                }
                              >
                                Create
                              </Button>
                              {/* </Link> */}
                            </div>

                            <div class="col-auto">
                              <a href="/create">
                                <div class="stat text-primary">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 448 512"
                                  >
                                    <path d="M432 256c0 17.69-14.33 32.01-32 32.01H256v144c0 17.69-14.33 31.99-32 31.99s-32-14.3-32-31.99v-144H48c-17.67 0-32-14.32-32-32.01s14.33-31.99 32-31.99H192v-144c0-17.69 14.33-32.01 32-32.01s32 14.32 32 32.01v144h144C417.7 224 432 238.3 432 256z" />
                                  </svg>
                                </div>
                              </a>
                            </div>
                          </div>

                          {/* <div class="mb-0">
                            <span class="text-danger">
                              
                              <i class="mdi mdi-arrow-bottom-right"></i> -3.65%
                            </span>
                            <span class="text-muted">Since last week</span>
                          </div> */}
                        </div>
                      </div>
                      <div class="card">
                        <div class="card-body">
                          <div class="row">
                            <div class="col mt-0">
                              {/* <Link to="/edit"> */}
                              <Button
                                variant="outline-primary"
                                onClick={() => (window.location.href = "/edit")}
                              >
                                Edit
                              </Button>
                              {/* </Link> */}
                            </div>

                            <div class="col-auto">
                              <a href="/edit">
                                <div class="stat text-primary">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 512 512"
                                  >
                                    <path d="M490.3 40.4C512.2 62.27 512.2 97.73 490.3 119.6L460.3 149.7L362.3 51.72L392.4 21.66C414.3-.2135 449.7-.2135 471.6 21.66L490.3 40.4zM172.4 241.7L339.7 74.34L437.7 172.3L270.3 339.6C264.2 345.8 256.7 350.4 248.4 353.2L159.6 382.8C150.1 385.6 141.5 383.4 135 376.1C128.6 370.5 126.4 361 129.2 352.4L158.8 263.6C161.6 255.3 166.2 247.8 172.4 241.7V241.7zM192 63.1C209.7 63.1 224 78.33 224 95.1C224 113.7 209.7 127.1 192 127.1H96C78.33 127.1 64 142.3 64 159.1V416C64 433.7 78.33 448 96 448H352C369.7 448 384 433.7 384 416V319.1C384 302.3 398.3 287.1 416 287.1C433.7 287.1 448 302.3 448 319.1V416C448 469 405 512 352 512H96C42.98 512 0 469 0 416V159.1C0 106.1 42.98 63.1 96 63.1H192z" />
                                  </svg>
                                </div>
                              </a>
                            </div>
                          </div>

                          {/* <div class="mb-0">
                            <span class="text-success">
                              
                              <i class="mdi mdi-arrow-bottom-right"></i> 5.25%
                            </span>
                            <span class="text-muted">Since last week</span>
                          </div> */}
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="card">
                        <div class="card-body">
                          <div class="row">
                            <div class="col mt-0">
                              <Button
                                variant="outline-primary"
                                onClick={() =>
                                  (window.location.href = "/delete")
                                }
                                disabled
                              >
                                Delete
                              </Button>
                            </div>

                            <div class="col-auto">
                              <div class="stat text-primary">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 448 512"
                                >
                                  <path d="M135.2 17.69C140.6 6.848 151.7 0 163.8 0H284.2C296.3 0 307.4 6.848 312.8 17.69L320 32H416C433.7 32 448 46.33 448 64C448 81.67 433.7 96 416 96H32C14.33 96 0 81.67 0 64C0 46.33 14.33 32 32 32H128L135.2 17.69zM394.8 466.1C393.2 492.3 372.3 512 346.9 512H101.1C75.75 512 54.77 492.3 53.19 466.1L31.1 128H416L394.8 466.1z" />
                                </svg>
                              </div>
                            </div>
                          </div>

                          {/* <div class="mb-0">
                            <span class="text-success">
                              
                              <i class="mdi mdi-arrow-bottom-right"></i> 6.65%
                            </span>
                            <span class="text-muted">Since last week</span>
                          </div> */}
                        </div>
                      </div>
                      <div class="card">
                        <div class="card-body">
                          <div class="row">
                            <div class="col mt-0">
                              <h1 class="mt-1 mb-3">{data.length}</h1>
                            </div>

                            <div class="col-auto">
                              <div class="stat text-primary">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 576 512"
                                >
                                  <path d="M55.1 56.04C55.1 42.78 66.74 32.04 79.1 32.04H111.1C125.3 32.04 135.1 42.78 135.1 56.04V176H151.1C165.3 176 175.1 186.8 175.1 200C175.1 213.3 165.3 224 151.1 224H71.1C58.74 224 47.1 213.3 47.1 200C47.1 186.8 58.74 176 71.1 176H87.1V80.04H79.1C66.74 80.04 55.1 69.29 55.1 56.04V56.04zM118.7 341.2C112.1 333.8 100.4 334.3 94.65 342.4L83.53 357.9C75.83 368.7 60.84 371.2 50.05 363.5C39.26 355.8 36.77 340.8 44.47 330.1L55.59 314.5C79.33 281.2 127.9 278.8 154.8 309.6C176.1 333.1 175.6 370.5 153.7 394.3L118.8 432H152C165.3 432 176 442.7 176 456C176 469.3 165.3 480 152 480H64C54.47 480 45.84 474.4 42.02 465.6C38.19 456.9 39.9 446.7 46.36 439.7L118.4 361.7C123.7 355.9 123.8 347.1 118.7 341.2L118.7 341.2zM512 64C529.7 64 544 78.33 544 96C544 113.7 529.7 128 512 128H256C238.3 128 224 113.7 224 96C224 78.33 238.3 64 256 64H512zM512 224C529.7 224 544 238.3 544 256C544 273.7 529.7 288 512 288H256C238.3 288 224 273.7 224 256C224 238.3 238.3 224 256 224H512zM512 384C529.7 384 544 398.3 544 416C544 433.7 529.7 448 512 448H256C238.3 448 224 433.7 224 416C224 398.3 238.3 384 256 384H512z" />
                                </svg>
                              </div>
                            </div>
                          </div>

                          {/* <div class="mb-0">
                            <span class="text-danger">
                              
                              <i class="mdi mdi-arrow-bottom-right"></i> -2.25%
                            </span>
                            <span class="text-muted">Since last week</span>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-xl-6 col-xxl-7">
                <div class="card flex-fill w-100">
                  <div class="card-header">
                    <h5 class="card-title mb-0">Recent Movement</h5>
                  </div>
                  <div class="card-body py-3">
                    <div class="chart chart-sm">
                      {/* <ButtonGroup>
                        {radios.map((radio, idx) => (
                          <ToggleButton
                            key={idx}
                            id={`radio-${idx}`}
                            type="radio"
                            variant={
                              idx % 2 ? "outline-danger" : "outline-success"
                            }
                            name="radio"
                            value={radio.value}
                            checked={radioValue === radio.value}
                            onChange={(e) =>
                              setRadioValue(e.currentTarget.value)
                            }
                          >
                            {radio.name}
                          </ToggleButton>
                        ))}
                      </ButtonGroup> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>

      {/* <script src="js/app.js"></script> */}
    </body>
  );
}

export default DashBoard;
