import React, { useEffect, useState } from "react";
import "./App.css";
import "./main.css";
import Home from "./Home";
import Login from "./Login";
import DashBoard from "./DashBoard";
import logo from "./logo.png";
import { UserAuthContextProvider } from "./UserAuthContext";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute";
import SignUp from "./SignUp";
import { css } from "@emotion/react";
import ClimbingBoxLoader from "react-spinners/ClimbingBoxLoader";
import CreateTracking from "./CreateTracking";
import TrackingPage from "./TrackingPage";
import Edit from "./Edit";
import EditTracking from "./EditTracking";
import { collection, query, onSnapshot } from "firebase/firestore";
import { db } from "./firebase";
const override = css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  margin-top: -10%;
`;

function App() {
  const [loading, setLoading] = useState(false);
  const trackingRef = collection(db, "info");
  const [details, setDetails] = useState([]);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 12000);
  }, []);

  const editHandler = () => {
    const q = query(trackingRef);
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      querySnapshot.forEach((doc) => {
        setDetails(doc.data());
      });

      // console.log(details);
    });
  };
  useEffect(() => {
    editHandler();
  }, []);

  return (
    <div>
      {loading ? (
        <ClimbingBoxLoader
          size={15}
          loading={loading}
          color={"#201e1e"}
          css={override}
        />
      ) : (
        <BrowserRouter>
          <div className="flex font-serif min-h-full flex-col items-stretch w-full">
            <div className="Header_header__1v0yI">
              <div className="container flex flex-wrap justify-between items-center mx-auto">
                <div
                  id="logo"
                  className="h-full flex w-3/12 lg:w-2/12 justify-between items-center"
                >
                  <img
                    src={logo}
                    className="h-12"
                    alt="FAST247"
                    onClick={() => (window.location.href = "/")}
                  />
                </div>
                <div className="flex justify-end items-center lg:hidden w-9/12">
                  {/* <nav className="flex flex-wrap lg:flex-nowrap ml-1 items-center justify-end w-full font-serif">
                    <div className="lg:hidden flex">
                      <a
                        style={{ borderRadius: "4px" }}
                        className="w-auto mb-0 whitespace-nowrap overflow-hidden overflow-ellipsis bg-transparentt font-medium lg:font-normal lg:bg-primary border-primary border-none sm:border text-sm text-primaryy lg:text-white p-3 flex justify-center"
                        href="/login"
                      >
                        Sign In
                      </a>
                    </div>
                  </nav> */}
                  <svg
                    stroke="currentColor"
                    fill="currentColor"
                    stroke-width="0"
                    viewBox="0 0 512 512"
                    className="text-4xl"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M432 176H80c-8.8 0-16-7.2-16-16s7.2-16 16-16h352c8.8 0 16 7.2 16 16s-7.2 16-16 16zM432 272H80c-8.8 0-16-7.2-16-16s7.2-16 16-16h352c8.8 0 16 7.2 16 16s-7.2 16-16 16zM432 368H80c-8.8 0-16-7.2-16-16s7.2-16 16-16h352c8.8 0 16 7.2 16 16s-7.2 16-16 16z"></path>
                  </svg>
                </div>
                <div className="w-full lg:w-10/12 hidden lg:flex flex-wrap h-full items-center">
                  <div className="w-full lg:w-6/12 flex justify-between">
                    {/* <nav className="flex flex-wrap justify-between w-full font-serif">
                      <div className="font-medium text-sm relative flex flex-wrap lg:block w-full lg:w-auto TopNav_hasSubmenu__2aQBg">
                        <a
                          className="hover:text-primaryy flex justify-between items-center py-4 w-full lg:py-0"
                          target="_self"
                          href="#"
                        >
                          Service Portfolio
                          <svg
                            stroke="currentColor"
                            fill="currentColor"
                            stroke-width="0"
                            viewBox="0 0 448 512"
                            className="inline-flex text-xs ml-2"
                            height="1em"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z"></path>
                          </svg>
                        </a>
                        <div className="relative lg:absolute lg:py-3 flex-col lg:shadow lg:rounded-lg w-full lg:w-52 bg-gray-100 lg:bg-white TopNav_subMenu__3mljD">
                          <a
                            className="px-3 border-b border-gray-200 lg:border-none lg:px-6 py-3 w-full flex hover:text-primaryy"
                            href="#"
                          >
                            Domestic Logistics
                          </a>
                          <a
                            className="px-3 border-b border-gray-200 lg:border-none lg:px-6 py-3 w-full flex hover:text-primaryy"
                            href="#"
                          >
                            Corporate Logistics
                          </a>
                          <a
                            className="px-3 border-b border-gray-200 lg:border-none lg:px-6 py-3 w-full flex hover:text-primaryy"
                            href="#"
                          >
                            Overseas Shipping
                          </a>
                          <a
                            className="px-3 border-b border-gray-200 lg:border-none lg:px-6 py-3 w-full flex hover:text-primaryy"
                            href="#"
                          >
                            E-commerce Logistics
                          </a>
                          <a
                            className="px-3 border-b border-gray-200 lg:border-none lg:px-6 py-3 w-full flex hover:text-primaryy"
                            href="#"
                          >
                            Wallet and Added Services
                          </a>
                        </div>
                      </div>
                      <div className="font-medium text-sm relative flex flex-wrap lg:block w-full lg:w-auto TopNav_hasSubmenu__2aQBg">
                        <a
                          className="hover:text-primaryy flex justify-between items-center py-4 w-full lg:py-0"
                          target="_self"
                          href="/"
                        >
                          Our Offices
                          <svg
                            stroke="currentColor"
                            fill="currentColor"
                            stroke-width="0"
                            viewBox="0 0 448 512"
                            className="inline-flex text-xs ml-2"
                            height="1em"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z"></path>
                          </svg>
                        </a>
                        <div className="relative lg:absolute lg:py-3 flex-col lg:shadow lg:rounded-lg w-full lg:w-52 bg-gray-100 lg:bg-white TopNav_subMenu__3mljD">
                          <a
                            className="px-3 border-b border-gray-200 lg:border-none lg:px-6 py-3 w-full flex hover:text-primaryy"
                            href=""
                          >
                            Nigeria
                          </a>
                          <a
                            className="px-3 border-b border-gray-200 lg:border-none lg:px-6 py-3 w-full flex hover:text-primaryy"
                            href="#"
                          >
                            Ghana
                          </a>
                          <a
                            className="px-3 border-b border-gray-200 lg:border-none lg:px-6 py-3 w-full flex hover:text-primaryy"
                            href="#"
                          >
                            United States
                          </a>
                          <a
                            className="px-3 border-b border-gray-200 lg:border-none lg:px-6 py-3 w-full flex hover:text-primaryy"
                            href="#"
                          >
                            United Kingdom
                          </a>
                        </div>
                      </div>
                      <div className="font-medium text-sm relative flex flex-wrap lg:block w-full lg:w-auto">
                        <a
                          className="hover:text-primaryy flex justify-between items-center py-4 w-full lg:py-0"
                          target="_blank"
                          href="#"
                        >
                          Blog
                        </a>
                      </div>
                      <div className="font-medium text-sm relative flex flex-wrap lg:block w-full lg:w-auto">
                        <a
                          className="hover:text-primaryy flex justify-between items-center py-4 w-full lg:py-0"
                          target="_self"
                          href="#"
                        >
                          FAQs
                        </a>
                      </div>
                      <div className="font-medium text-sm relative flex flex-wrap lg:block w-full lg:w-auto">
                        <a
                          className="hover:text-primaryy flex justify-between items-center py-4 w-full lg:py-0"
                          target="_self"
                          href="#"
                        >
                          About Us
                        </a>
                      </div>
                    </nav> */}
                  </div>
                  <div className="w-full lg:w-6/12 flex justify-between">
                    {/* <nav className="flex flex-wrap lg:flex-nowrap ml-1 items-center justify-end w-full font-serif">
                      <div className="lg:flex hidden">
                        <a
                          style={{ borderRadius: "4px" }}
                          className="w-auto mb-0 whitespace-nowrap overflow-hidden overflow-ellipsis bg-transparentt font-medium lg:font-normal lg:bg-primary border-primary border-none sm:border text-sm text-primaryy lg:text-white p-3 flex justify-center"
                          href="/login"
                        >
                          Sign In
                        </a>
                      </div>
                    </nav> */}
                  </div>
                </div>
              </div>
            </div>
            <UserAuthContextProvider>
              <Routes>
                <Route path="/" element={<Home />} />

                <Route
                  path="/Dashboard"
                  element={
                    <ProtectedRoute>
                      <DashBoard />
                    </ProtectedRoute>
                  }
                />

                <Route
                  path="/edit"
                  element={
                    <ProtectedRoute>
                      <Edit />
                    </ProtectedRoute>
                  }
                />

                <Route path="/login" element={<Login />} />

                {details.signUpPage !== "false" && (
                  <Route path="/adminsignup" element={<SignUp />} />
                )}
                <Route path="/tracking/:id" element={<TrackingPage />} />

                {/* <Route
                  path="/tracking/"
                  element={<Navigate to="/dashboard" />}
                /> */}

                <Route path="/update/:id" element={<EditTracking />} />
                <Route
                  path="/create"
                  element={
                    <ProtectedRoute>
                      <CreateTracking />
                    </ProtectedRoute>
                  }
                />
              </Routes>
            </UserAuthContextProvider>
            {/* <Route
            path="/amazontokendashboard"
            element={
              <ProtectedRoute>
                <DashBoard />
              </ProtectedRoute>
            }
          />

          <Route
            path="/buytoken"
            element={
              <ProtectedRoute>
                <BuyToken />
              </ProtectedRoute>
            }
          /> */}

            <div className="Footer_footer__7dIj9">
              <div className="container flex flex-wrap justify-around py-8 lg:py-24 mx-auto">
                <div className="w-full lg:w-auto">
                  <h4 className="font-bold text-lg mb-3 text-white">
                    © {details.year} FAST247 Logistics
                  </h4>
                  <nav className="flex flex-col"></nav>
                </div>
                <div className="w-full lg:w-auto">
                  <h4 className="font-bold text-lg mb-3 text-white">
                    {/* +1 (773) 916-7152 */}
                    {details.phoneNumber}
                  </h4>
                  <nav className="flex flex-col">
                    {/* <a
                      className="Footer_footer-link__3B_NT"
                      target="_self"
                      href="#"
                    >
                      Service Portfolio
                    </a> */}
                    {/* <a
                      className="Footer_footer-link__3B_NT"
                      target="_self"
                      href="#"
                    >
                      About us
                    </a> */}
                    {/* <a
                      className="Footer_footer-link__3B_NT"
                      target="_blank"
                      href="#"
                    >
                      Blog
                    </a> */}
                    <a
                      className="Footer_footer-link__3B_NT"
                      target="_self"
                      href="#"
                    >
                      Terms and Conditions
                    </a>
                  </nav>
                </div>
                <div className="w-full lg:w-auto">
                  <h4 className="font-bold text-lg mb-3 text-white">
                    Other Services
                  </h4>
                  <nav className="flex flex-col">
                    <a
                      className="Footer_footer-link__3B_NT"
                      target="_self"
                      href="#"
                    >
                      Get a Quote
                    </a>
                    {/* <a
                      className="Footer_footer-link__3B_NT"
                      target="_self"
                      href="#"
                    >
                      Schedule a Pickup
                    </a> */}
                    <a
                      className="Footer_footer-link__3B_NT"
                      target="_self"
                      href="#"
                    >
                      FAST247 Alpha
                    </a>
                    <a
                      className="Footer_footer-link__3B_NT"
                      target="_self"
                      href="#"
                    >
                      Overseas Shipping
                    </a>
                    <a
                      className="Footer_footer-link__3B_NT"
                      target="_self"
                      href="#"
                    >
                      Report Issues
                    </a>
                  </nav>
                </div>
                <div>
                  <h4 className="font-bold text-lg mb-3 text-white">
                    Connect with us
                  </h4>
                  <nav className="flex">
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="#"
                      title="facebook"
                      className="text-white mr-6 text-lg"
                    >
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        stroke-width="0"
                        viewBox="0 0 512 512"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M504 256C504 119 393 8 256 8S8 119 8 256c0 123.78 90.69 226.38 209.25 245V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11 71.69h-57.78V501C413.31 482.38 504 379.78 504 256z"></path>
                      </svg>
                    </a>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="#"
                      title="instagram"
                      className="text-white mr-6 text-lg"
                    >
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        stroke-width="0"
                        viewBox="0 0 448 512"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"></path>
                      </svg>
                    </a>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="#"
                      title="twitter"
                      className="text-white mr-6 text-lg"
                    >
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        stroke-width="0"
                        viewBox="0 0 512 512"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"></path>
                      </svg>
                    </a>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="#"
                      title="linkedin"
                      className="text-white mr-6 text-lg"
                    >
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        stroke-width="0"
                        viewBox="0 0 448 512"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z"></path>
                      </svg>
                    </a>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </BrowserRouter>
      )}
    </div>
  );
}

export default App;
