import React, { useState } from "react";
import { Alert } from "react-bootstrap";
import { useUserAuth } from "./UserAuthContext";
import { useNavigate } from "react-router-dom";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const { logIn } = useUserAuth();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await logIn(email, password);
      navigate("/dashboard");
      // db.collection("users").add({
      //   password: password,
      //   email: email,
      //   timestamp: firebase.firestore.FieldValue.serverTimestamp(),
      // });
    } catch (err) {
      setErrorMessage(err.message);
    }
  };

  return (
    <main className="flex w-full bg-gray-100 flex-shrink-0 flex-grow">
      <div className="Toastify"></div>
      <div className="application"></div>
      <div className="container flex mx-auto">
        <div className="w-full py-20">
          <h1 className="text-2xl text-center font-black">Sign in</h1>
          <div className="max-w-lg mx-auto my-10">
            <div className="w-full">
              <form className="flex flex-wrap">
                <div className="mb-8 w-full">
                  <div className="w-full">
                    <div className="w-full h-full relative">
                      <input
                        id="email"
                        name="email"
                        className="w-full p-44 borderr border-gray-400 rounded-md"
                        type="email"
                        placeholder="Email Address"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="mb-5 w-full">
                  <div className="w-full">
                    <div className="w-full h-full relative">
                      <input
                        id="password"
                        name="password"
                        className="w-full p-44 borderr border-gray-400 rounded-md"
                        type="password"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                      />
                      <button
                        title="Show Password"
                        className="absolute bg-gray-100 rounded right-4 top-1/4 p-2"
                        type="button"
                      >
                        <svg
                          stroke="currentColor"
                          fill="none"
                          stroke-width="2"
                          viewBox="0 0 24 24"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          height="1em"
                          width="1em"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                          <circle cx="12" cy="12" r="3"></circle>
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
                {errorMessage !== "" && (
                  <Alert variant="danger">{errorMessage}</Alert>
                )}
                <div className="text-sm w-full mb-5">
                  <a className="underline font-medium" href="#">
                    Forgot Password?
                  </a>
                </div>
                <button
                  type="submit"
                  className="mb-5 text-white bg-secondaryy font-bold w-full p-44 rounded-xl"
                  onClick={handleSubmit}
                >
                  Login
                </button>
              </form>
            </div>
            <div className="text-sm w-full text-center">
              Don't have an account?
              <a className="underline font-medium" href="#">
                Sign Up
              </a>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default Login;
