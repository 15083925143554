import React, { useEffect, useState } from "react";
import {
  Container,
  ProgressBar,
  Card,
  ListGroup,
  Button,
  Alert,
  Row,
  Col,
} from "react-bootstrap";
import { useParams } from "react-router-dom";
import ClimbingBoxLoader from "react-spinners/ClimbingBoxLoader";
import { css } from "@emotion/react";
import { db } from "./firebase";
import { collection, query, where, onSnapshot } from "firebase/firestore";
import { contains } from "@firebase/util";
const override = css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  margin-top: -10%;
`;

function TrackingPage() {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const trackingRef = collection(db, "details");
  const [trackingDetails, setTrackingDetails] = useState([]);
  const [old, setOld] = useState(true);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, []);

  useEffect(() => {
    const q = query(trackingRef, where("trackingNumber", "==", id));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      querySnapshot.forEach((doc) => {
        setTrackingDetails(doc.data());
      });
      console.log(trackingDetails.bluePercentage);
    });
  }, []);
  // console.log(trackingDetails.lenght);
  // console.log(id);
  return (
    <div className="fxg-main-content" style={{ marginTop: "50px" }}>
      {loading ? (
        <ClimbingBoxLoader
          size={15}
          loading={loading}
          color={"#201e1e"}
          css={override}
        />
      ) : (
        <div className="container">
          {trackingDetails.length !== 0 && (
            <>
              <h1 className="fxg-title">Delivery Status</h1>

              <Container>
                <>
                  {trackingDetails.bluePercentage &&
                    trackingDetails.redPercentage && (
                      <ProgressBar style={{ marginBottom: "20px" }}>
                        <ProgressBar
                          striped
                          variant="primary"
                          now={trackingDetails.bluePercentage}
                          key={1}
                          animated
                        />

                        <ProgressBar
                          striped
                          variant="danger"
                          now={trackingDetails.redPercentage}
                          key={2}
                          animated
                        />
                      </ProgressBar>
                    )}

                  {trackingDetails.color && (
                    <ProgressBar
                      animated
                      now={trackingDetails.progress}
                      variant={trackingDetails.color}
                      style={{ marginBottom: "20px" }}
                    />
                  )}
                  {trackingDetails.deliveryMessage && (
                    <Alert variant="success">
                      <Alert.Heading>
                        <Container>
                          <Row>
                            <Col xs={2} md={1}>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 448 512"
                                width="25px"
                                color="#0f5132"
                                height="20px"
                                display="initial"
                              >
                                <path d="M182.6 246.6C170.1 259.1 149.9 259.1 137.4 246.6L57.37 166.6C44.88 154.1 44.88 133.9 57.37 121.4C69.87 108.9 90.13 108.9 102.6 121.4L159.1 178.7L297.4 41.37C309.9 28.88 330.1 28.88 342.6 41.37C355.1 53.87 355.1 74.13 342.6 86.63L182.6 246.6zM182.6 470.6C170.1 483.1 149.9 483.1 137.4 470.6L9.372 342.6C-3.124 330.1-3.124 309.9 9.372 297.4C21.87 284.9 42.13 284.9 54.63 297.4L159.1 402.7L393.4 169.4C405.9 156.9 426.1 156.9 438.6 169.4C451.1 181.9 451.1 202.1 438.6 214.6L182.6 470.6z" />
                              </svg>
                            </Col>
                            <Col xs={10} md={11}>
                              <p
                                style={{ fontWeight: "600", color: "#0f5132" }}
                              >
                                Package Notice
                              </p>
                            </Col>
                          </Row>
                        </Container>
                      </Alert.Heading>
                      <p>{trackingDetails.deliveryMessage}</p>
                    </Alert>
                  )}
                  {trackingDetails.infoMessage && (
                    <Alert variant="primary">
                      <Alert.Heading>
                        <Container>
                          <Row>
                            <Col xs={2} md={1}>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 512 512"
                              >
                                <path d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM216 336h24V272H216c-13.3 0-24-10.7-24-24s10.7-24 24-24h48c13.3 0 24 10.7 24 24v88h8c13.3 0 24 10.7 24 24s-10.7 24-24 24H216c-13.3 0-24-10.7-24-24s10.7-24 24-24zm40-144c-17.7 0-32-14.3-32-32s14.3-32 32-32s32 14.3 32 32s-14.3 32-32 32z" />
                              </svg>
                              {/* <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 448 512"
                                width="25px"
                                color="#0f5132"
                                height="20px"
                                display="initial"
                              >
                                <path d="M182.6 246.6C170.1 259.1 149.9 259.1 137.4 246.6L57.37 166.6C44.88 154.1 44.88 133.9 57.37 121.4C69.87 108.9 90.13 108.9 102.6 121.4L159.1 178.7L297.4 41.37C309.9 28.88 330.1 28.88 342.6 41.37C355.1 53.87 355.1 74.13 342.6 86.63L182.6 246.6zM182.6 470.6C170.1 483.1 149.9 483.1 137.4 470.6L9.372 342.6C-3.124 330.1-3.124 309.9 9.372 297.4C21.87 284.9 42.13 284.9 54.63 297.4L159.1 402.7L393.4 169.4C405.9 156.9 426.1 156.9 438.6 169.4C451.1 181.9 451.1 202.1 438.6 214.6L182.6 470.6z" />
                              </svg> */}
                            </Col>
                            <Col xs={10} md={11}>
                              <p style={{ fontWeight: "600", color: "#black" }}>
                                Package Update
                              </p>
                            </Col>
                          </Row>
                        </Container>
                      </Alert.Heading>
                      <p style={{ color: "black" }}>
                        {trackingDetails.infoMessage}
                      </p>
                    </Alert>
                  )}
                  {trackingDetails.errorMessage && (
                    <Alert variant="danger">
                      <Alert.Heading>
                        <Container>
                          <Row>
                            <Col xs={2} md={1}>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 512 512"
                                width="25px"
                                color="#842029"
                                height="20px"
                                display="initial"
                              >
                                <path
                                  fill="currentcolor"
                                  d="M506.3 417l-213.3-364c-16.33-28-57.54-28-73.98 0l-213.2 364C-10.59 444.9 9.849 480 42.74 480h426.6C502.1 480 522.6 445 506.3 417zM232 168c0-13.25 10.75-24 24-24S280 154.8 280 168v128c0 13.25-10.75 24-23.1 24S232 309.3 232 296V168zM256 416c-17.36 0-31.44-14.08-31.44-31.44c0-17.36 14.07-31.44 31.44-31.44s31.44 14.08 31.44 31.44C287.4 401.9 273.4 416 256 416z"
                                />
                              </svg>
                            </Col>
                            <Col xs={10} md={11}>
                              <p
                                style={{ fontWeight: "600", color: "#842029" }}
                              >
                                You got an error!
                              </p>
                            </Col>
                          </Row>
                        </Container>
                      </Alert.Heading>
                      <p>{trackingDetails.errorMessage}</p>
                    </Alert>
                  )}

                  <Card
                    style={{
                      color: "#4D148C",
                      fontSize: "15px",
                      marginBottom: "25px",
                    }}
                  >
                    <Card.Header>Shipment Information</Card.Header>
                    <Card.Body>
                      <Card.Header>Tracking Number</Card.Header>
                      <ListGroup variant="flush">
                        <ListGroup.Item>
                          {trackingDetails.trackingNumber}
                        </ListGroup.Item>
                      </ListGroup>
                      <Card.Header>Current Location</Card.Header>
                      <ListGroup variant="flush">
                        <ListGroup.Item>
                          {trackingDetails.currentLocation}
                        </ListGroup.Item>
                      </ListGroup>
                      <Card.Header>Packages</Card.Header>
                      <ListGroup variant="flush">
                        <ListGroup.Item>
                          {trackingDetails.packages}
                        </ListGroup.Item>
                      </ListGroup>
                      <Card.Header>Destination</Card.Header>
                      <ListGroup variant="flush">
                        <ListGroup.Item>
                          {trackingDetails.destination}
                        </ListGroup.Item>
                      </ListGroup>
                      {/* <Card.Header>Pick-up Date</Card.Header>
                      <ListGroup variant="flush">
                        <ListGroup.Item>
                          {trackingDetails.pickUpDate}
                        </ListGroup.Item>
                      </ListGroup> */}
                      <Card.Header>Delivery Date</Card.Header>
                      <ListGroup variant="flush">
                        <ListGroup.Item>
                          {trackingDetails.expectedDeliveryDate}
                        </ListGroup.Item>
                      </ListGroup>
                      <Card.Header>Mode of Shipment</Card.Header>
                      <ListGroup variant="flush">
                        <ListGroup.Item>
                          {trackingDetails.typeOfShipment}
                        </ListGroup.Item>
                      </ListGroup>
                      <Card.Header>Previous Location</Card.Header>
                      <ListGroup variant="flush">
                        <ListGroup.Item>
                          {trackingDetails.previousLocation}
                        </ListGroup.Item>
                      </ListGroup>
                      <Card.Header>Service</Card.Header>
                      <ListGroup variant="flush">
                        <ListGroup.Item>
                          {trackingDetails.service}
                        </ListGroup.Item>
                      </ListGroup>
                    </Card.Body>
                    <Card.Header>Receiver's Details</Card.Header>
                    <Card.Body>
                      <Card.Header>Recievers Name</Card.Header>
                      <ListGroup variant="flush">
                        <ListGroup.Item>
                          {trackingDetails.recieversName}
                        </ListGroup.Item>
                      </ListGroup>
                      <Card.Header>Contact</Card.Header>
                      <ListGroup variant="flush">
                        <ListGroup.Item>
                          {trackingDetails.phoneNumber}
                        </ListGroup.Item>
                        <Card.Header>Address</Card.Header>
                        <ListGroup variant="flush">
                          <ListGroup.Item>
                            {trackingDetails.address}
                          </ListGroup.Item>
                        </ListGroup>
                      </ListGroup>
                    </Card.Body>
                  </Card>
                </>
              </Container>
            </>
          )}

          {trackingDetails.length === 0 && (
            <Container>
              <Alert variant="danger">
                <Alert.Heading>Opps! Wrong Tracking Number</Alert.Heading>
                <p>Please enter correct Tracking ID</p>
                <hr />
                <div className="d-flex justify-content-end">
                  <Button
                    variant="outline-success"
                    onClick={() => (window.location.href = "/")}
                    size="sm"
                  >
                    Go Back
                  </Button>
                </div>
              </Alert>
            </Container>
          )}
        </div>
      )}
    </div>
  );
}
export default TrackingPage;
