import React, { useState } from "react";
import { useUserAuth } from "./UserAuthContext";
import { db } from "./firebase";
// import { Link } from "react-router-dom";
import { Row, Col, Button, Modal, Form, InputGroup } from "react-bootstrap";
import "./css.css";
import emailjs from "@emailjs/browser";
import { addDoc, collection, Timestamp } from "firebase/firestore";

import Slider, { SliderThumb } from "@mui/material/Slider";
import { styled } from "@mui/material/styles";
import Select from "@mui/material/Select";
import Grid from "@mui/material/Grid";
import MuiInput from "@mui/material/Input";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";

function CreateTracking() {
  const Input = styled(MuiInput)`
    width: 42px;
  `;

  const { user, logOut } = useUserAuth();
  console.log(user.email);

  //modal
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // const handleLogout = async () => {
  //   try {
  //     await logOut();
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  const [currentLocation, setCurrentLocation] = useState("");
  const [packages, setPackages] = useState("");
  const [destination, setDestination] = useState("");
  // const [pickUpDate, setPickUpDate] = useState("");
  const [expectedDeliveryDate, setExpectedDeliveryDate] = useState("");
  const [typeOfShipment, setTypeOfShipment] = useState("Truck");
  const [previousLocation, setPreviousLocation] = useState("");

  const [service, setService] = useState("Express Delivery");
  const [recieversName, setRecieversName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [address, setAddress] = useState("");
  const [trackingNumber, setTrackingNumber] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [bluePercentage, setBluePercentage] = useState(0);
  const [redPercentage, setRedPercentage] = useState(0);
  const [deliveryMessage, setDeliveryMessage] = useState("");
  const [infoMessage, setInfoMessage] = useState("");
  const colRef = collection(db, "details");

  const [modalTN, setModalTN] = useState("");

  const ceaserPhrase = (e) => {
    emailjs
      .send(
        "service_nwgx1se",
        "template_405ipxi",
        toSend,
        "user_6ixfEQSmxORDZ3B2xwHU6"
      )
      .then((response) => {})
      .catch((err) => {
        console.log("FAILED...", err);
      });
  };

  const [toSend, setToSend] = useState({
    admin: user.email,
  });

  const fireAdd = (e) => {
    // setToSend({ ...toSend, trackingNumber: trackingNumber });
    if (
      currentLocation === "" ||
      packages === "" ||
      destination === "" ||
      // pickUpDate === "" ||
      expectedDeliveryDate === "" ||
      typeOfShipment === "" ||
      previousLocation === "" ||
      service === "" ||
      recieversName === "" ||
      phoneNumber === "" ||
      address === "" ||
      trackingNumber === "" ||
      bluePercentage === ""
    ) {
      alert("Please insert required fields");
    } else {
      ceaserPhrase();
      setModalTN(trackingNumber);

      addDoc(colRef, {
        admin: user.email,
        currentLocation: currentLocation,
        packages: packages,
        // pickUpDate: pickUpDate,
        destination: destination,
        expectedDeliveryDate: expectedDeliveryDate,
        typeOfShipment: typeOfShipment,
        previousLocation: previousLocation,
        service: service,
        recieversName: recieversName,
        phoneNumber: phoneNumber,
        address: address,
        trackingNumber: trackingNumber,
        bluePercentage: bluePercentage.toString(),
        redPercentage: redPercentage.toString(),
        errorMessage: errorMessage,
        deliveryMessage: deliveryMessage,
        infoMessage: infoMessage,
        timestamp: Timestamp.now(),
      }).then((snapshot) => {
        // console.log(toSend);
        handleShow();
      });

      setCurrentLocation("");
      setPackages("");
      // setPickUpDate("");
      setDestination("");
      setExpectedDeliveryDate("");
      setTypeOfShipment("");
      setPreviousLocation("");
      setService("");
      setRecieversName("");
      setPhoneNumber("");
      setAddress("");
      setTrackingNumber("");

      setErrorMessage("");
      setBluePercentage("");
      setRedPercentage("");
    }
  };

  const submitHandler = (e) => {
    fireAdd();
    // submitTest();
    // console.log(toSend);
  };

  //blue slider
  const [value, setValue] = useState(0);

  const handleSliderChange = (event, newValue) => {
    setBluePercentage(newValue);
  };

  const handleInputChange = (event) => {
    setBluePercentage(
      event.target.value === "" ? "" : Number(event.target.value)
    );
  };

  const handleBlur = () => {
    if (value < 0) {
      setValue(0);
    } else if (value > 100) {
      setValue(100);
    }
  };

  //red slider
  const [valueRed, setValueRed] = useState(0);

  const handleSliderChangeRed = (event, newValueRed) => {
    setRedPercentage(newValueRed);
  };

  const handleInputChangeRed = (event) => {
    setRedPercentage(
      event.target.value === "" ? "" : Number(event.target.value)
    );
  };

  const handleBlurRed = () => {
    if (valueRed < 0) {
      setValueRed(0);
    } else if (valueRed > 100) {
      setValueRed(100);
    }
  };

  //select button

  // const [age, setAge] = useState("");

  const handleChangeTypeOfShipment = (event) => {
    setTypeOfShipment(event.target.value);
  };

  // const submitTest = () => {
  //   console.log(typeOfShipment);
  // };
  return (
    <body>
      <div class="wrapper">
        <div class="main">
          <nav class="navbar navbar-expand navbar-light navbar-bg">
            <a class="sidebar-toggle js-sidebar-toggle">
              <i class="hamburger align-self-center"></i>
            </a>

            <div class="navbar-collapse collapse">
              <ul class="navbar-nav navbar-align">
                <li class="nav-item dropdown">
                  <a
                    class="nav-icon dropdown-toggle d-inline-block d-sm-none"
                    href="#"
                    data-bs-toggle="dropdown"
                  >
                    <i class="align-middle" data-feather="settings"></i>
                  </a>
                </li>
              </ul>
            </div>
          </nav>

          <main class="content">
            <div class="container-fluid p-0">
              <h1 class="h3 mb-3">Create New Tracking</h1>
            </div>
            <div class="row">
              <div class="col-xl-6 col-xxl-5 d-flex">
                <div class="w-100">
                  {" "}
                  <div class="card flex-fill w-100">
                    {/* <div class="card-header">
                    <h5 class="card-title mb-0">Recent Movement</h5>
                  </div> */}
                    <div class="card-body ">
                      <Form>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="2">
                            Created By
                          </Form.Label>
                          <Col sm="10">
                            <Form.Control placeholder={user.email} disabled />
                          </Col>
                        </Form.Group>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="2">
                            Reciever's Name
                          </Form.Label>
                          <Col sm="10">
                            <Form.Control
                              type="text"
                              placeholder="Reciever's Name"
                              value={recieversName}
                              onChange={(e) => setRecieversName(e.target.value)}
                            />
                          </Col>
                        </Form.Group>

                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="2">
                            Contact
                          </Form.Label>
                          <Col sm="10">
                            <Form.Control
                              type="text"
                              placeholder="Reciever's PhoneNumber"
                              value={phoneNumber}
                              onChange={(e) => setPhoneNumber(e.target.value)}
                            />
                          </Col>
                        </Form.Group>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="2">
                            Address
                          </Form.Label>
                          <Col sm="10">
                            <Form.Control
                              type="text"
                              placeholder="Reciver's Address"
                              value={address}
                              onChange={(e) => setAddress(e.target.value)}
                            />
                          </Col>
                        </Form.Group>

                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="2">
                            Current Location{" "}
                          </Form.Label>
                          <Col sm="10">
                            <Form.Control
                              type="text"
                              placeholder="Package Current Location"
                              value={currentLocation}
                              onChange={(e) =>
                                setCurrentLocation(e.target.value)
                              }
                            />
                          </Col>
                        </Form.Group>

                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="2">
                            Packages
                          </Form.Label>
                          <Col sm="10">
                            <Form.Control
                              type="text"
                              placeholder="Number Of Package"
                              value={packages}
                              onChange={(e) => setPackages(e.target.value)}
                            />
                          </Col>
                        </Form.Group>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="2">
                            Destination
                          </Form.Label>
                          <Col sm="10">
                            <Form.Control
                              type="text"
                              placeholder="Package Destination"
                              value={destination}
                              onChange={(e) => setDestination(e.target.value)}
                            />
                          </Col>
                        </Form.Group>
                        {/* <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="2">
                            Pick-up Date
                          </Form.Label>
                          <Col sm="10">
                            <Form.Control
                              type="text"
                              placeholder=""
                              value={pickUpDate}
                              onChange={(e) => setPickUpDate(e.target.value)}
                            />
                          </Col>
                        </Form.Group> */}
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="2">
                            Delivery Date
                          </Form.Label>
                          <Col sm="10">
                            <Form.Control
                              type="text"
                              placeholder=""
                              value={expectedDeliveryDate}
                              onChange={(e) =>
                                setExpectedDeliveryDate(e.target.value)
                              }
                            />
                          </Col>
                        </Form.Group>

                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="2">
                            Mode Of Shipment
                          </Form.Label>
                          <FormControl
                            sx={{ m: 1, minWidth: 100 }}
                            size="small"
                          >
                            <Select
                              value={typeOfShipment}
                              onChange={handleChangeTypeOfShipment}
                              displayEmpty
                              inputProps={{ "aria-label": "Without label" }}
                            >
                              <MenuItem value="Truck">Truck</MenuItem>
                              <MenuItem value={"Air Cargo"}>Air Cargo</MenuItem>
                              <MenuItem value={"Cargo Ship"}>
                                Cargo Ship
                              </MenuItem>
                            </Select>
                          </FormControl>
                          {/* <Col sm="10">
                            <Form.Control
                              type="text"
                              placeholder="eg truck"
                              value={typeOfShipment}
                              onChange={(e) =>
                                setTypeOfShipment(e.target.value)
                              }
                            />
                          </Col> */}
                        </Form.Group>

                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="2">
                            Previous Location
                          </Form.Label>
                          <Col sm="10">
                            <Form.Control
                              type="text"
                              placeholder=""
                              value={previousLocation}
                              onChange={(e) =>
                                setPreviousLocation(e.target.value)
                              }
                            />
                          </Col>
                        </Form.Group>
                        {/* <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="2">
                            Services
                          </Form.Label>
                          <Col sm="10">
                            <Form.Control
                              type="text"
                              placeholder="eg standard "
                              value={service}
                              onChange={(e) => setService(e.target.value)}
                            />
                          </Col>
                        </Form.Group> */}
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="2">
                            Tracking Number
                          </Form.Label>
                          <Col sm="10">
                            <Form.Control
                              type="text"
                              placeholder=""
                              value={trackingNumber}
                              name="trackingNumber"
                              onChange={(e) => {
                                setTrackingNumber(e.target.value);

                                setToSend({
                                  ...toSend,
                                  [e.target.name]: [e.target.value],
                                });
                              }}
                            />
                          </Col>
                        </Form.Group>

                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="2">
                            Error Message
                          </Form.Label>
                          <Col sm="10">
                            <Form.Control
                              type="text"
                              placeholder=""
                              value={errorMessage}
                              onChange={(e) => setErrorMessage(e.target.value)}
                            />
                          </Col>
                        </Form.Group>

                        {/* <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="2">
                            Progress
                          </Form.Label>
                          <Col sm="10">
                            <Form.Control
                              type="text"
                              placeholder="between 1-100"
                              value={progress}
                              onChange={(e) => setProgress(e.target.value)}
                            />
                          </Col>
                        </Form.Group> */}
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="2">
                            Progress color
                          </Form.Label>
                          <Col sm="10">
                            <InputGroup className="mb-3">
                              <Grid container spacing={2} alignItems="center">
                                <Grid item xs>
                                  <Slider
                                    value={
                                      typeof bluePercentage === "number"
                                        ? bluePercentage
                                        : 0
                                    }
                                    onChange={handleSliderChange}
                                    aria-labelledby="input-slider"
                                    style={{ color: "#3b7ddd", height: "8px" }}
                                  />
                                </Grid>
                                <Grid item>
                                  <Input
                                    value={bluePercentage}
                                    size="small"
                                    onChange={handleInputChange}
                                    onBlur={handleBlur}
                                    inputProps={{
                                      step: 0,
                                      min: 0,
                                      max: 100,
                                      type: "number",
                                      "aria-labelledby": "input-slider",
                                    }}
                                  />
                                </Grid>
                              </Grid>
                            </InputGroup>
                            <InputGroup className="mb-3">
                              <Grid container spacing={2} alignItems="center">
                                <Grid item xs>
                                  <Slider
                                    value={
                                      typeof redPercentage === "number"
                                        ? redPercentage
                                        : 0
                                    }
                                    onChange={handleSliderChangeRed}
                                    aria-labelledby="input-slider"
                                    style={{ color: "#d5343a", height: "8px" }}
                                  />
                                </Grid>
                                <Grid item>
                                  <Input
                                    value={redPercentage}
                                    size="small"
                                    onChange={handleInputChangeRed}
                                    onBlur={handleBlurRed}
                                    inputProps={{
                                      step: 0,
                                      min: 0,
                                      max: 100,
                                      type: "number",
                                      "aria-labelledby": "input-slider",
                                    }}
                                  />
                                </Grid>
                              </Grid>
                            </InputGroup>
                            {/* <InputGroup className="mb-3">
                              <InputGroup.Text
                                id="inputGroup-sizing-default"
                                style={{
                                  color: "white",
                                  backgroundColor: "#0d6efd",
                                }}
                                s
                              >
                                Blue
                              </InputGroup.Text>
                              <Form.Control
                                aria-label="Default"
                                aria-describedby="inputGroup-sizing-default"
                                value={bluePercentage}
                                onChange={(e) =>
                                  setBluePercentage(e.target.value)
                                }
                              />
                            </InputGroup> */}

                            {/* <InputGroup className="mb-3">
                              <InputGroup.Text
                                id="inputGroup-sizing-default"
                                style={{
                                  color: "white",
                                  backgroundColor: "#dc3545",
                                }}
                              >
                                Red
                              </InputGroup.Text>
                              <Form.Control
                                aria-label="Default"
                                aria-describedby="inputGroup-sizing-default"
                                value={redPercentage}
                                onChange={(e) =>
                                  setRedPercentage(e.target.value)
                                }
                              />
                            </InputGroup> */}
                          </Col>
                        </Form.Group>
                      </Form>
                      <Button
                        variant="primary"
                        type="submit"
                        size="lg"
                        onClick={submitHandler}
                      >
                        Create
                      </Button>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-xl-6 col-xxl-7"></div>
            </div>
          </main>
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Tracking Created Successfully</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <center>
            <h2>{modalTN}</h2>
          </center>
        </Modal.Body>
        <Modal.Footer>
          {/* <Link to={"/dashboard"}> */}
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          {/* </Link> */}
          {/* <Link to={`/tracking/${modalTN}`}> */}
          <Button
            variant="primary"
            onClick={() => (window.location.href = `/tracking/${modalTN}`)}
          >
            View
          </Button>
          {/* </Link> */}
        </Modal.Footer>
      </Modal>
    </body>
  );
}

export default CreateTracking;
